import { MyTripsFilter } from "redmond";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../api/config";
import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";

export const getTabs = (
  tripsFilter: MyTripsFilter,
  tabs: { label: MyTripsFilter }[]
) => {
  const corpHidePriceFreeze =
    useExperimentsById("corp-hide-price-freeze")?.variant === "available";
  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const filteredTabs = tabs.filter((tab) => {
    if (corpHidePriceFreeze && tab.label === MyTripsFilter.PRIZE_FREEZES) {
      return false;
    }
    if (tab.label === MyTripsFilter.TRIPS_WITH_OFFERS) {
      return tripsFilter === MyTripsFilter.TRIPS_WITH_OFFERS;
    }
    if (
      (!isCorpTenant(config.TENANT) || isApprovalsV2Enabled) &&
      tab.label === MyTripsFilter.TRIP_REQUESTS
    ) {
      return false;
    }
    return true;
  });

  return filteredTabs;
};
