import { ExperienceReservationResponse } from "redmond";
import { put } from "redux-saga/effects";
import { fetchExperiences } from "../../../api/v1/itinerary/fetchExperiences";
import { actions } from "../actions";

export function* fetchExperiencesSaga(action: actions.IFetchExperiences) {
  try {
    const experiencesReservationResponse: ExperienceReservationResponse =
      yield fetchExperiences(action.request);

    const {
      future: newFuture,
      canceled: newCanceled,
      past: newPast,
      present: newPresent,
    } = experiencesReservationResponse.itineraries;

    yield put(
      actions.setExperiences(
        {
          present: newPresent ? newPresent.map((x) => x) : [],
          canceled: newCanceled ? newCanceled.map((x) => x) : [],
          past: newPast ? newPast.map((x) => x) : [],
          future: newFuture ? newFuture.map((x) => x) : [],
        },
        action.request.states
      )
    );
  } catch (e) {
    yield put(actions.fetchExperiencesFailed(action.request));
    console.error(e);
  }
}
