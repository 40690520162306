import React from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ExperienceReservationDetails, MyTripsModalTypes } from "redmond";
import {
  ActionLink,
  AvailabilityDuration,
  AvailabilityLanguage,
  Icon,
  IconName,
} from "halifax";

import styles from "./styles.module.scss";
import {
  CANCELLATION_POLICY,
  DATE_FORMAT,
  END_POINT,
  STARTING_POINT,
  VIEW_ITINERARY,
  VIEW_POLICY_DETAILS,
} from "../../constants";
import { renderAddress } from "../ExperienceMap";

export interface IExperienceCardContentProps {
  reservation: ExperienceReservationDetails;
  onOpenModal: (type: MyTripsModalTypes) => void;
}

export const ExperienceCardContent = ({
  reservation,
  onOpenModal,
}: IExperienceCardContentProps) => {
  const {
    experienceSelection: {
      travelDate,
      providerExperienceStartTime,
      languageGuide,
    },
    experienceDetails,
    cancellationPolicy,
  } = reservation;
  const { logistics, duration, supplierInfo, itinerary } =
    experienceDetails || {};

  const sameAsStart =
    logistics?.startLocations?.[0]?.address ===
    logistics?.endLocations?.[0]?.address;

  return (
    <Box className={styles["experience-card-content-container"]}>
      <Box className={styles["date-time-container"]}>
        {dayjs(travelDate).format(DATE_FORMAT)}
        {providerExperienceStartTime && (
          <>
            <span className={styles.divider}>|</span>
            {dayjs(providerExperienceStartTime, "HH:mm").format("h:mm A")}
          </>
        )}
      </Box>
      <Box className={styles["infos-container"]}>
        <AvailabilityDuration duration={duration} />
        <span className={styles.divider}>|</span>
        {languageGuide && (
          <>
            <AvailabilityLanguage languages={[languageGuide]} />
            <span className={styles.divider}>|</span>
          </>
        )}
        <Typography className={styles["experiences-shop-overview-title"]}>
          {supplierInfo?.name}
        </Typography>
      </Box>
      <Box className={styles["map-section"]}>
        {!!logistics?.startLocations?.length && (
          <Box className={styles["map-starting-point"]}>
            <Typography variant="h6" className={styles["map-titles"]}>
              <Icon name={IconName.B2BMapPin} />
              {STARTING_POINT}
            </Typography>
            {renderAddress(logistics.startLocations[0])}
          </Box>
        )}
        {!!logistics?.endLocations?.length && (
          <Box className={styles["map-end-point"]}>
            <Typography variant="h6" className={styles["map-titles"]}>
              <Icon name={IconName.FlagIcon} />
              {END_POINT}
            </Typography>
            {renderAddress(logistics.endLocations[0], sameAsStart)}
          </Box>
        )}
      </Box>
      {itinerary && (
        <ActionLink
          className={styles["action-link"]}
          onClick={() => onOpenModal(MyTripsModalTypes.ExperienceItinerary)}
          content={VIEW_ITINERARY}
        />
      )}
      {cancellationPolicy && (
        <Box className={styles["cancellation-policy-container"]}>
          <Typography>
            <strong>{CANCELLATION_POLICY}</strong>
          </Typography>
          <Typography variant="body2">
            {cancellationPolicy?.cancellationPolicy}
          </Typography>
          <ActionLink
            className={styles["action-link"]}
            onClick={() =>
              onOpenModal(MyTripsModalTypes.ExperienceCancellationPolicy)
            }
            content={VIEW_POLICY_DETAILS}
          />
        </Box>
      )}
    </Box>
  );
};
